import { useEffect, useRef, useState } from 'react';
import SystemInfo from '../System/SystemInfo';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import store, { clearLocalStorage } from 'redux/store';
import applicationActions from 'redux/actions/application';
import authActions from 'redux/actions/auth';
import { LicenceType, UserSettings } from 'types/auth';
import { oldAppUrlGenerate } from 'utils/data-processors/url';
import moment from 'moment';
import { AppState } from 'types';
import { bindActionCreators, Dispatch } from 'redux';
import calendarActions from 'redux/actions/calendar';
import { connect } from 'react-redux';
import { Restaurant } from 'types/calendar';
import { AppConnectionStatus } from 'types/application';
import { getAuthenticatedUserCall } from 'api/auth';
import { applyPolyfills, defineCustomElements as jeepSqlite } from 'jeep-sqlite/loader';
import './Authenticated.scss';
import {
  removeToken,
  removeTokenFromCookie,
  responseHasTokenError,
} from 'utils/auth/tokenManagement';
import ConnectionListenerWrapper from '../Wrappers/ConnectionListenerWrapper';
import { NavigationWrapper } from 'app/components/Navigation';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { initializeApplicationData } from 'app/components/Authenticated/_config';
import { GlobalModals } from 'app/components/GlobalModals';
import { useScreenSize } from 'hooks/useScreenSize';
import { Box } from '@mui/material';
import useRouteMatcher from 'app/router/hooks/useRouteMatcher';
import paths from 'app/router/paths';
import rootActions from 'redux/actions/app/root';

interface DispatchProps {
  getRestaurant: (_isOffline?: boolean | null) => void;
  getTables: (_isOffline?: boolean | null) => void;
  updateTime: (time: moment.Moment) => void;
  updateConnectionStatus: (connectionStatus: AppConnectionStatus) => void;
  getUserSettings: () => void;
}

interface StateProps {
  userSettings: UserSettings;
  restaurant: Restaurant;
  connectionStatus: AppConnectionStatus;
}

type Props = DispatchProps & StateProps;

store.dispatch<any>(applicationActions.getLanguages());

applyPolyfills().then(async () => {
  await jeepSqlite(window);
});

const Authenticated = (props: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const clockRef = useRef<any>(undefined);
  const { isOffline } = props.connectionStatus;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const current_date = useAppSelector((state) => state.app.app.current_date);
  const { isMobile } = useScreenSize();
  const { pathname } = useLocation();
  const isSettingsView = pathname.includes('settings');
  const { routeIs } = useRouteMatcher();

  useEffect(() => {
    getAuthenticatedUserCall()
      .then(async () => {
        setIsLoading(false);
        props.getUserSettings();
      })
      .then(async () => {
        if (!isOffline) {
          await initializeApplicationData(dispatch, enqueueSnackbar, t, current_date);
        }
      })
      .then(() => {
        props.getRestaurant(isOffline);
        props.getTables(isOffline);
      })
      .catch((error) => {
        const responseCode = error?.data?.status || error?.status;
        if (responseHasTokenError(error)) {
          removeTokenFromCookie();
          removeToken();
          clearLocalStorage();
          navigate(paths.auth.login);
          dispatch(rootActions.resetStoreToDefault());
        }
        if (error?.code >= 500 || responseCode >= 500) {
          store.dispatch({
            type: 'Application.SetConnectionStatus',
            payload: {
              connectionStatus: {
                serverErrorCode: responseCode,
                isOffline: true,
              },
            },
          });
        }
      });
  }, [isOffline]);

  useEffect(() => {
    if (routeIs(paths.home)) {
      navigate(paths.calendar.calendar);
    }
  }, []);

  // Make sure that user have active license and redirect to the settings if have not.
  useEffect(() => {
    if (
      props.userSettings.activeLicense &&
      // eslint-disable-next-line
      props.userSettings.activeLicense.type == LicenceType.nolicence
    ) {
      window.location.href = oldAppUrlGenerate('/settings', false);
    }
  }, [props.userSettings]);

  // Update website title.
  useEffect(() => {
    if (props.restaurant.title) {
      document.title = props.restaurant.title;
    }
  }, [props.restaurant]);

  useEffect(() => {
    clockRef.current = setInterval(() => {
      props.updateTime(moment());
    }, 60000);

    return () => {
      clearInterval(clockRef.current);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isMobile && !isSettingsView) {
      navigate(paths.calendar.reservationList);
    }
  }, [isMobile]);

  const [visibleHeight, setVisibleHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setVisibleHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Box className="tablein-app" sx={{ height: visibleHeight }}>
      <GlobalModals />
      {!isLoading && (
        <>
          <SystemInfo />
          <ConnectionListenerWrapper>
            <NavigationWrapper>
              <Outlet />
            </NavigationWrapper>
          </ConnectionListenerWrapper>
        </>
      )}
    </Box>
  );
};

function mapStateToProps(state: AppState): StateProps {
  return {
    userSettings: state.auth.userSettings,
    restaurant: state.calendar.restaurant,
    connectionStatus: state.application.connectionStatus,
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    getRestaurant: bindActionCreators(calendarActions.getRestaurant, dispatch),
    getTables: bindActionCreators(calendarActions.getTables, dispatch),
    updateTime: bindActionCreators(applicationActions.updateTime, dispatch),
    updateConnectionStatus: bindActionCreators(applicationActions.updateConnectionStatus, dispatch),
    getUserSettings: bindActionCreators(authActions.getUserSettings, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Authenticated);
