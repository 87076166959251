import { ReactNode } from 'react';
import { Button } from '@mui/material';

interface OwnProps {
  children: ReactNode;
  onClick: () => void;
  disabled?: boolean;
  sx?: object;
  active?: boolean;
  iconColor?: string;
  highlightedHoverColor?: string;
  buttonBackground?: string;
  buttonHoverBackground?: string;
}

export const CalendarControlsButton = ({
  children,
  onClick,
  disabled,
  sx,
  active,
  iconColor,
  highlightedHoverColor,
  buttonBackground,
  buttonHoverBackground,
}: OwnProps) => {
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      variant={'outlined'}
      sx={(theme) => ({
        background:
          buttonBackground || (active ? theme.palette.brandYellow : theme.palette.brandWhiteDark),
        color: active ? theme.palette.white : theme.palette.navyBlue,
        padding: '9px 7px',
        border: 0,
        transition: 'none',
        minWidth: 40,
        width: 40,
        height: 40,
        '& svg.ic-stroke path': {
          stroke: theme.palette.cometLight,
          strokeWidth: 1.5,
        },
        '& svg.ic-fill path': {
          fill: theme.palette.cometLight,
        },
        ...sx,
        '&:hover': {
          border: 0,
          '& svg.ic-stroke path': {
            stroke: iconColor || theme.palette.dodgerBlue,
          },
          '& svg.ic-fill path': {
            fill: iconColor || theme.palette.dodgerBlue,
          },
          background:
            buttonHoverBackground ||
            (active ? theme.palette.brandYellow : theme.palette.titanWhite),
          '& .highlighted': {
            color: highlightedHoverColor,
          },
        },
        '& .highlighted': {
          color: active && theme.palette.white,
        },
      })}
    >
      {children}
    </Button>
  );
};
