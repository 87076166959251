import { MessageVariants } from 'enums/notifications';
import { OptionsObject, SnackbarMessage } from 'notistack';
import { TFunction } from 'i18next';
import reservationsActions from 'redux/actions/app/reservations';
import profileActions from 'redux/actions/app/profile';
import timeAndLanguageActions from 'redux/actions/app/timeAndLanguage';
import configActions from 'redux/actions/app/config';
import openingHoursActions from 'redux/actions/app/openingHours';
import appActions from 'redux/actions/app/app';
import roomsActions from 'redux/actions/app/rooms';
import tablesActions from 'redux/actions/app/tables';
import reservationSettingsActions from 'redux/actions/app/reservationSettings';
import specialOffersActions from 'redux/actions/app/specialOffers';
import preferencesActions from 'redux/actions/app/preferences';
import notificationSettingsActions from 'redux/actions/app/notificationSettings';

export const initializeApplicationData = async (
  dispatch: any,
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => void,
  t: TFunction,
  current_date: number,
) => {
  try {
    await dispatch(configActions.getAppConfig());
  } catch (error) {
    enqueueSnackbar(t('configurationFetchError'), {
      variant: MessageVariants.Error,
    });
  }
  try {
    await dispatch(profileActions.getProfile());
  } catch (error) {
    enqueueSnackbar(t('profileFetchError'), {
      variant: MessageVariants.Error,
    });
  }
  try {
    await dispatch(timeAndLanguageActions.getTimeAndLanguage());
  } catch (error) {
    enqueueSnackbar(t('timeAndLanguageFetchError'), {
      variant: MessageVariants.Error,
    });
  }
  try {
    await dispatch(openingHoursActions.getOpeningHours());
    await dispatch(appActions.updateOpeningHour());
  } catch (error) {
    enqueueSnackbar(t('openingHoursFetchError'), {
      variant: MessageVariants.Error,
    });
  }
  try {
    await dispatch(reservationSettingsActions.getReservationSettings());
    dispatch(preferencesActions.setDefaultPreferences());
  } catch (error) {
    enqueueSnackbar(t('reservationSettingsFetchError'), {
      variant: MessageVariants.Error,
    });
  }
  try {
    await dispatch(roomsActions.getRooms());
  } catch (error) {
    enqueueSnackbar(t('roomsFetchError'), {
      variant: MessageVariants.Error,
    });
  }
  try {
    await dispatch(tablesActions.getTables());
  } catch (error) {
    enqueueSnackbar(t('tablesFetchError'), {
      variant: MessageVariants.Error,
    });
  }
  try {
    const twoWeeks = 1209600;
    const start_time = current_date - twoWeeks;
    const end_time = current_date + twoWeeks;

    await dispatch(
      reservationsActions.getBaseReservations({
        start_time,
        end_time,
      }),
    );
  } catch (error) {
    enqueueSnackbar(t('baseReservationsFetchError'), {
      variant: MessageVariants.Error,
    });
  }
  try {
    await dispatch(reservationsActions.getFullReservationsForDay());
  } catch (error) {
    enqueueSnackbar(t('fullReservationsFetchError'), {
      variant: MessageVariants.Error,
    });
  }
  try {
    await dispatch(specialOffersActions.getSpecialOffers());
  } catch (error) {
    enqueueSnackbar(t('specialOffersFetchError'), {
      variant: MessageVariants.Error,
    });
  }
  try {
    await dispatch(notificationSettingsActions.getNotificationSMSSettings());
  } catch (error) {
    enqueueSnackbar(t('notificationSettingsSMSFetchError'), {
      variant: MessageVariants.Error,
    });
  }
};
