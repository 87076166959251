import { Button, SxProps } from '@mui/material';
import { MutableRefObject, ReactNode } from 'react';
import { useThemeMode } from 'hooks/useThemeMode';

interface OwnProps {
  onClick: () => void;
  variant?: 'text' | 'contained' | 'outlined' | undefined;
  name: string;
  color?: 'primary' | 'secondary' | 'error';
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  disabled?: boolean;
  textVariantBgColor?: string;
  sx?: SxProps;
  buttonRef?: MutableRefObject<any>;
}

export const CustomNarrowButton = ({
  onClick,
  variant = 'outlined',
  name,
  color = 'secondary',
  startIcon,
  endIcon,
  disabled,
  textVariantBgColor,
  sx,
  buttonRef,
}: OwnProps) => {
  const { isDarkMode } = useThemeMode();

  return (
    <Button
      variant={variant}
      color={color}
      onClick={onClick}
      startIcon={startIcon}
      endIcon={endIcon}
      disabled={disabled}
      ref={buttonRef}
      sx={{
        height: 'fit-content',
        padding: '3px 6px',
        fontSize: '13px',
        border: '1px solid',
        borderColor: variant === 'outlined' ? 'currentColor' : 'transparent',
        '&:hover': {
          border: '1px solid',
          borderColor: variant === 'outlined' ? 'currentColor' : 'transparent',
        },
        '& svg.ic-stroke path': {
          stroke: 'currentColor',
        },
        '& .MuiButton-startIcon': {
          marginRight: '4px',
          marginLeft: 0,
        },
        '& .MuiButton-endIcon': {
          marginLeft: '4px',
          marginRight: 0,
        },
        ...(variant === 'text' &&
          textVariantBgColor && {
            backgroundColor: textVariantBgColor,
            '&:hover': {
              backgroundColor: textVariantBgColor,
            },
            ...(disabled && {
              backgroundColor: 'disabled',
            }),
          }),
        ...(variant === 'text' &&
          isDarkMode && {
            backgroundColor: '#242D4C',
            color: '#7C8194',
            '&:hover': {
              backgroundColor: '#242D4C',
              color: '#7C8194',
            },
          }),
        ...(variant === 'contained' && {
          borderColor: 'transparent',
          '&:hover': {
            borderColor: 'transparent',
          },
        }),
        ...sx,
      }}
    >
      {name}
    </Button>
  );
};
