import { Button, SxProps, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReactNode } from 'react';

interface OwnProps {
  isSelected?: boolean;
  firstText?: string;
  secondText?: string;
  onClick: () => void;
  sx?: SxProps;
  icon?: ReactNode;
  color?: 'secondary' | 'error';
  sxText?: object;
  disabled?: boolean;
}

export const CustomOutlinedButton = ({
  isSelected,
  firstText,
  secondText,
  onClick,
  sx,
  icon,
  color = 'secondary',
  sxText,
  disabled,
}: OwnProps) => {
  const theme = useTheme();

  return (
    <Button
      variant={isSelected ? 'contained' : 'text'}
      color={color}
      disabled={disabled}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '40px',
        padding: '5px',
        flex: 1,
        minWidth: 0,
        backgroundColor: isSelected ? 'secondary' : 'zircon',
        '& svg.ic-stroke path': {
          stroke: `${theme.palette.comet}`,
        },
        border: 0,
        '&:hover': {
          border: 0,
        },
        ...sx,
      }}
      onClick={onClick}
    >
      {icon}
      {firstText && (
        <Typography
          sx={{
            fontSize: '12px',
            fontWeight: 600,
            lineHeight: 1,
            color: isSelected ? theme.palette.white : 'navyBlue',
            marginBottom: '4px',
            ...sxText,
          }}
        >
          {firstText}
        </Typography>
      )}
      {secondText && (
        <Typography
          sx={{
            fontSize: '12px',
            lineHeight: 1,
            color: isSelected ? theme.palette.white : 'comet',
            ...sxText,
          }}
        >
          {secondText}
        </Typography>
      )}
    </Button>
  );
};
