import { Box, ToggleButton, ToggleButtonGroup, SxProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useController, useFormContext } from 'react-hook-form';
import { CustomLabel } from 'app/components/CustomLabel';

interface IButtonOption {
  text: string;
  color: string;
  value: string;
}

interface OwnProps {
  name: string;
  label?: string;
  description?: string;
  sx?: SxProps;
  sxToggleButtonGroup?: object;
  sxToggleButton?: object;
  buttonOptions?: IButtonOption[];
  isBiggerButton?: boolean;
}

export const ButtonsWithColors = ({
  name,
  label,
  description,
  sx,
  sxToggleButtonGroup,
  sxToggleButton,
  buttonOptions,
  isBiggerButton,
}: OwnProps) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
    defaultValue: 'blue',
  });

  const buttons = buttonOptions || [
    { text: 'blue', color: 'accent.blue', value: 'blue' },
    { text: 'green', color: 'accent.green', value: 'green' },
    { text: 'yellow', color: 'accent.yellow', value: 'yellow' },
    { text: 'purple', color: 'accent.violet', value: 'purple' },
    { text: 'red', color: 'accent.red', value: 'red' },
    { text: 'brown', color: 'accent.brown', value: 'brown' },
  ];

  const handleColor = (_: unknown, newColor: string) => {
    onChange(newColor);
  };

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <CustomLabel label={label} name={name} description={description} />
      <ToggleButtonGroup
        value={value}
        exclusive
        onChange={handleColor}
        sx={{
          display: 'flex',
          gap: '8px',
          flexWrap: 'wrap',
          '& .MuiToggleButtonGroup-grouped': {
            '&.MuiToggleButton-root': {
              borderLeft: '1px solid',
              border: '1px solid',
            },
          },
          ...sxToggleButtonGroup,
        }}
      >
        {buttons.map(({ text, color, value }, index) => (
          <ToggleButton
            key={index}
            value={value}
            sx={{
              border: `1px solid ${color}`,
              borderRadius: '30px !important',
              color,
              fontSize: '12px',
              lineHeight: 1,
              fontWeight: 600,
              padding: 0,
              minHeight: '24px',
              minWidth: '70px',
              flex: 1,
              textTransform: 'capitalize',
              '&.Mui-selected': {
                backgroundColor: color,
                color: 'white',
                borderColor: 'transparent !important',
                '&:hover': {
                  backgroundColor: color,
                  color: 'white',
                },
              },
              '&:hover': {
                backgroundColor: color,
                color: 'white',
                borderColor: 'transparent !important',
              },
              ...sxToggleButton,
              ...(isBiggerButton && {
                fontSize: '14px',
                borderRadius: '5px !important',
                minHeight: '40px',
                padding: '6px 32px',
                width: 'fit-content',
                flex: 'unset',
              }),
            }}
            aria-label={text}
          >
            {t(text)}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Box>
  );
};
