import { ReactNode } from 'react';
import { Box } from '@mui/material';

interface OwnProps {
  children: ReactNode;
}

export const CalendarControlsItem = ({ children }: OwnProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {children}
    </Box>
  );
};
