import { ReactComponent as RefreshIcon } from 'images/icons/ic-repeat.svg';
import { CalendarControlsButton } from 'app/components/CalendarControls/_components/CalendarControlsButton';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import reservationsActions from 'redux/actions/app/reservations';

const refreshStyles = {
  color: 'white',
  background: 'none',
  border: '0',
  textDecoration: 'underline',
  cursor: 'pointer',
  padding: '0',
  '&:hover': {
    backgroundColor: 'transparent',
  },
};

interface RefreshButtonProps {
  isText?: boolean;
}

export const RefreshButton = ({ isText }: RefreshButtonProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { is_get_reservations_details_loading } = useAppSelector((state) => state.app.loading);

  const onRefresh = async () => {
    !is_get_reservations_details_loading &&
      (await dispatch(reservationsActions.getFullReservationsForDay()));
  };

  return (
    <>
      {isText ? (
        <Button sx={refreshStyles} onClick={onRefresh}>
          {t('Refresh')}
        </Button>
      ) : (
        <CalendarControlsButton onClick={onRefresh}>
          <RefreshIcon
            className={`${is_get_reservations_details_loading && 'is-spinning'} ic-stroke`}
            width={20}
            height={20}
          />
        </CalendarControlsButton>
      )}
    </>
  );
};
