import { ButtonBase } from '@mui/material';
import { RotatingArrow } from 'app/components/StyledComponents/RotatingArrow';

interface OwnProps {
  onClick: () => void;
  open: boolean;
}

export const ArrowUpButton = ({ onClick, open }: OwnProps) => {
  return (
    <ButtonBase
      onClick={onClick}
      sx={{
        display: 'flex',
        width: 'fit-content',
        height: 'fit-content',
        borderRadius: '50%',
        marginLeft: '8px',
      }}
    >
      <RotatingArrow open={open} isMargin={false} />
    </ButtonBase>
  );
};
